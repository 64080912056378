<template>
  <div class="form-content">
    <Loader v-if="loading" />
    <div style="padding: 30px">
      <v-row class="form-headline-content">
        <v-col>
          <h2>Baugeld Vergleich</h2>
        </v-col>
        <v-col>
          <!--          <div class="download-file">
            <v-card-actions class="pl-0 pr-0 flex-wrap">
              <v-tooltip :attach="true" bottom nudge-top="5" :color="baseColor">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" @click="exportPdf">
                    <v-icon color="#fff">mdi-file-pdf-box</v-icon>
                  </v-btn>
                </template>
                <span class="inter hint-color">PDF erstellen</span>
              </v-tooltip>
            </v-card-actions>
            <v-card-actions class="pl-0 pr-0 flex-wrap">
              <v-tooltip :attach="true" bottom nudge-top="5" :color="baseColor">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    :color="color"
                    v-bind="attrs"
                    v-on="on"
                    @click="printForm"
                  >
                    <v-icon color="#fff">{{ 'mdi-printer' }}</v-icon>
                  </v-btn>
                </template>
                <span class="inter hint-color">Seite drucken</span>
              </v-tooltip>
            </v-card-actions>
          </div>-->
        </v-col>
      </v-row>
      <div>
        <v-row class="flex-wrap banks-column">
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
          >
            <fieldset>
              <legend>Ihre Bank oder Vergleichsbank 1</legend>
              <v-row class="flex-column">
                <v-col class="pb-0">
                  <anbieter-selectbox
                    storePath="formParamHypotheken.bankname1"
                    :hypotheken="true"
                    :fest-geld="false"
                  />
                </v-col>
                <v-col class="pb-0 pt-0">
                  <anbieter-chart-color-selectbox
                    storePath="chartParamHypotheken.datasetColor1"
                    :hypotheken="true"
                    :fest-geld="false"
                  />
                </v-col>
              </v-row>
            </fieldset>
          </v-col>
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
          >
            <fieldset>
              <legend>Bank 2</legend>
              <v-row class="flex-column">
                <v-col class="pb-0">
                  <anbieter-selectbox
                    storePath="formParamHypotheken.bankname2"
                    :hypotheken="true"
                    :fest-geld="false"
                  />
                </v-col>
                <v-col class="pb-0 pt-0">
                  <anbieter-chart-color-selectbox
                    :hypotheken="true"
                    :fest-geld="false"
                    storePath="chartParamHypotheken.datasetColor2"
                  />
                </v-col>
              </v-row>
            </fieldset>
          </v-col>
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
          >
            <fieldset>
              <legend>Bank 3</legend>
              <v-row class="flex-column">
                <v-col class="pb-0">
                  <anbieter-selectbox
                    storePath="formParamHypotheken.bankname3"
                    :hypotheken="true"
                    :fest-geld="false"
                  />
                </v-col>
                <v-col class="pt-0 pb-0">
                  <anbieter-chart-color-selectbox
                    :hypotheken="true"
                    :fest-geld="false"
                    storePath="chartParamHypotheken.datasetColor3"
                  />
                </v-col>
              </v-row>
            </fieldset>
          </v-col>
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
            v-if="isShowAdditionalBanks.bank4"
          >
            <fieldset>
              <legend>Bank 4</legend>
              <v-row class="flex-column">
                <v-col class="pb-0">
                  <anbieter-selectbox
                    storePath="formParamHypotheken.bankname4"
                    :hypotheken="true"
                    :fest-geld="false"
                  />
                </v-col>
                <v-col class="pb-0 pt-0">
                  <anbieter-chart-color-selectbox
                    :hypotheken="true"
                    :fest-geld="false"
                    storePath="chartParamHypotheken.datasetColor4"
                  />
                </v-col>
              </v-row>
            </fieldset>
          </v-col>
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
            v-if="isShowAdditionalBanks.bank5"
          >
            <fieldset>
              <legend>Bank 5</legend>
              <v-row class="flex-column">
                <v-col class="pb-0">
                  <anbieter-selectbox
                    storePath="formParamHypotheken.bankname5"
                    :hypotheken="true"
                    :fest-geld="false"
                  />
                </v-col>
                <v-col class="pb-0 pt-0">
                  <anbieter-chart-color-selectbox
                    :hypotheken="true"
                    :fest-geld="false"
                    storePath="chartParamHypotheken.datasetColor5"
                  />
                </v-col>
              </v-row>
            </fieldset>
          </v-col>
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
            @click="handleShowBankChanged"
          >
            <AddBankCard :isShowAdditionalBanks="isShowAdditionalBanks" />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
          >
            <v-card-text class="pl-0 font"> Zeitraum </v-card-text>
            <v-select
              :color="color"
              item-color="selected"
              :attach="true"
              background-color="white"
              item-text="text"
              item-value="value"
              solo
              v-model.number="zeitraum"
              dense
              :items="[
                { text: 0, value: 0 },
                { text: 2, value: 2 },
                { text: 4, value: 4 },
                { text: 6, value: 6 },
                { text: 8, value: 8 },
                { text: 10, value: 10 },
              ]"
            >
              <template #selection="{ item }">
                <span>{{ item.text | zeitraumJahre }}</span>
              </template>
              <template v-slot:item="{ item }">
                <span>{{ item.text | zeitraumJahre }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col
            v-if="isDemoUser && zeitraum === 0"
            class="d-flex align-center"
          >
            <Alert
              type="info"
              color="orange"
              message="Die Auswahl eines Zeitraums ist in der Demoversion nicht möglich.
              Sie sehen Daten für das Jahr 2020"
            />
          </v-col>
          <v-col
            v-if="zeitraum !== 0 && !isDemoUser"
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
          ></v-col>
          <v-col
            v-if="zeitraum !== 0 && !isDemoUser"
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
          ></v-col>
          <v-col
            v-if="zeitraum === 0 && !isDemoUser"
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
          >
            <v-card-text class="font pl-0"> von </v-card-text>
            <date-picker
              storePath="formParamHypotheken.zeitraumVon"
              :max-date="new Date().toISOString().substr(0, 10)"
              min-date="2010-01-01"
              :hypotheken="true"
              :fest-geld="false"
            />
          </v-col>
          <v-col
            v-if="zeitraum === 0 && !isDemoUser"
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
          >
            <v-card-text class="font pl-0"> bis </v-card-text>
            <date-picker
              storePath="formParamHypotheken.zeitraumBis"
              :max-date="new Date().toISOString().substr(0, 10)"
              min-date="2010-01-01"
              :hypotheken="true"
              :fest-geld="false"
            />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
            class="pt-0 chips-container"
          >
            <v-row>
              <v-col class="baugeld-Effektivzins-content">
                <p>Effektivzins für eine Beleihung von</p>
                <v-tooltip
                  :attach="true"
                  bottom
                  nudge-top="5"
                  :color="baseColor"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="info-baugeld-icon">
                      <v-icon :color="color">mdi-alert-circle-outline</v-icon>
                    </div>
                  </template>
                  <span class="inter hint-color">
                    Die Beleihung bezieht sich auf einen Kaufpreis von 250.000
                    Euro im Jahr 2010, der<br />
                    jährlich um 15.000 Euro erhöht wird.<br />Immowert im Jahr
                    2015 = 325.000 Euro und im Jahr 2020 = 400.000 Euro<br />Die
                    Tilgung wurde ebenfalls entsprechend angehoben, damit eine
                    durchschnittliche<br />
                    Finanzierung abgebildet wird</span
                  >
                </v-tooltip>
              </v-col>
            </v-row>
            <v-chip-group column v-model.number="beleihung">
              <v-chip
                active-class="active-tag"
                v-for="(tag, index) in beleihungTags"
                :key="index"
                :value="tag.value"
              >
                {{ tag.text }}
              </v-chip>
            </v-chip-group>
            <p class="beleihung-placeholder">Ohne Grundbuchkosten</p>
          </v-col>
          <v-col cols="4" class="pt-0 chips-container">
            <v-card-text class="pl-0 font"> Sollzinsbindung </v-card-text>
            <v-chip-group column v-model.number="sollzinsbindung">
              <v-chip
                active-class="active-tag"
                v-for="(tag, index) in sollzinsbindungTags"
                :key="index"
                :value="tag.value"
              >
                {{ tag.text }}
              </v-chip>
            </v-chip-group>
          </v-col>
          <v-col class="hide"></v-col>
        </v-row>
        <v-row class="align-center">
          <v-col></v-col>
          <v-col>
            <v-btn
              class="anzeigen-btn action-btn"
              color="primary"
              @click="submitForm"
              >auswertung anzeigen</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              class="pl-0 action-btn"
              :color="color"
              plain
              @click="resetForm"
              >zurücksetzen</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapGetters, mapMutations } from 'vuex';

import AnbieterSelectbox from '../../common/BanksSelectBox/AnbieterSelectbox';
import DatePicker from '../../common/DatePicker/DatePicker';
import AnbieterChartColorSelectbox from '../../common/ChartColorSelectBox/AnbieterChartColorSelectbox';
import Loader from '../../common/Loader/Loader';
import AddBankCard from '../../common/AddBankCard/AddBankCard';
import Alert from '../../common/Alert/Alert';

import {
  BASE_BLACK_COLOR,
  BASE_COLOR,
} from '../../../../../src/utils/constants';
import {
  BELEIHUNG_TAGS_OPTIONS,
  SOLLZINSBINDUNG_TAGS_OPTIONS,
} from '../../../utils/constants';

export default {
  name: 'Form',
  components: {
    AnbieterSelectbox,
    DatePicker,
    AnbieterChartColorSelectbox,
    Loader,
    AddBankCard,
    Alert,
  },
  props: {
    loadPdf: Function,
  },
  computed: {
    ...mapGetters({
      isAuthorized: 'auth/isAuthorized',
      formParam: 'param/getFormParamHypotheken',
      user: 'auth/getUser',
    }),
    ...mapFields('param', ['formParamHypotheken.zeitraum']),
    ...mapFields('param', ['formParamHypotheken.zeitraumVon']),
    ...mapFields('param', ['formParamHypotheken.zeitraumBis']),
    ...mapFields('param', ['formParamHypotheken.beleihung']),
    ...mapFields('param', ['formParamHypotheken.sollzinsbindung']),
    dynamicCol() {
      if (
        Object.keys(this.isShowAdditionalBanks).some(
          (key) => this.isShowAdditionalBanks[key]
        )
      ) {
        return { xs: '12', sm: '12', md: '6', lg: '4', xl: '2' };
      }
      return { xs: '12', sm: '12', md: '6', lg: '3', xl: '2' };
    },
    isDemoUser() {
      return this.user?.isDemoUser || false;
    },
  },
  data() {
    return {
      isShowAdditionalBanks: {
        bank4: false,
        bank5: false,
      },
      sollzinsbindungTags: SOLLZINSBINDUNG_TAGS_OPTIONS,
      beleihungTags: BELEIHUNG_TAGS_OPTIONS,
      color: BASE_BLACK_COLOR,
      baseColor: BASE_COLOR,
      loading: false,
      isUpdate: false,
    };
  },
  methods: {
    ...mapMutations({
      saveParamHypoteken: 'param/saveParamHypoteken',
      updateCopyForm: 'param/updateCopyForm',
    }),
    handleShowBankChanged() {
      if (this.isShowAdditionalBanks.bank4 === false) {
        this.isShowAdditionalBanks = { bank4: true, bank5: false };
      } else {
        this.isShowAdditionalBanks = { bank4: true, bank5: true };
      }
    },
    exportPdf() {
      this.loadPdf();
    },
    printForm() {
      window.print();
    },
    async submitForm() {
      this.loading = true;
      await this.loadChartDataHypotheken();
      this.loading = false;
    },
    resetForm() {
      this.$store.dispatch('resetHypotheken');
    },
    async loadChartDataHypotheken() {
      try {
        await this.$store.dispatch(
          'result/loadChartDataHypotheken',
          this.$store.state.param.formParamHypotheken
        );
        this.updateCopyForm({
          copyFormParamHypotheken: this.$store.state.param.formParamHypotheken,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async loadAnbieter() {
      try {
        await this.$store.dispatch('anbieter/loadAnbieterHypotheken', 1);
        await this.$store.dispatch('anbieter/loadAnbieterHypotheken', 2);
        await this.$store.dispatch('anbieter/loadAnbieterHypotheken', 4);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    async isAuthorized(val) {
      if (val) {
        this.loading = true;
        await this.$store.dispatch('param/loadParamHypotheken');
        await this.$store.dispatch(
          'result/loadChartDataHypothekenFromLocalStorage'
        );
        await this.loadAnbieter();
        this.loading = false;
      }
    },
  },
  async created() {
    if (this.isAuthorized) {
      this.loading = true;
      await this.$store.dispatch('param/loadParamHypotheken');
      await this.$store.dispatch(
        'result/loadChartDataHypothekenFromLocalStorage'
      );
      await this.loadAnbieter();
      this.loading = false;
    }
  },
  updated() {
    this.saveParamHypoteken();
  },
};
</script>

<style lang="scss" scoped>
.form-content {
  padding-bottom: 30px;
}
.card-text {
  font-weight: bold;
  padding-left: 0;
  white-space: pre;
}
.dropdown.is-fullwidth {
  width: 100%;
}
.dropdown-trigger {
  width: 100%;
}
.dropdown .button {
  display: flex;
  width: 100%;
  justify-content: left;
}
.dropdown .button .button-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown .button .icon.is-right {
  margin-left: auto !important;
}
.v-card__actions > button {
  padding: 0 !important;
  min-width: 40px !important;
}
.v-btn__content {
  & > i {
    padding: 5px;
    border-radius: 5px;
    background-color: $text;
  }
}
.v-text-field__details {
  display: none !important;
  position: absolute;
}
.info-baugeld-icon:hover {
  cursor: pointer;
}
.baugeld-Effektivzins-content {
  display: flex;
  margin-bottom: 10px;
  & > p {
    margin: 0 9px 0 0;
  }
}
.beleihung-placeholder {
  padding-top: 5px;
  margin-bottom: 0;
  color: grey;
  font-size: 14px;
}
@media screen and (max-width: 1260px) {
  .baugeld-Effektivzins-content {
    flex-direction: column;
  }
}
@media screen and (max-width: 350px) {
  .hide {
    display: none;
  }
}
</style>
