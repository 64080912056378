<template>
  <div class="report-wrapper">
    <Loader v-if="loading" />
    <CopyIframeDialog
      :iframe="iframe"
      :dialog="iframeDialog"
      @changeIframeDialog="handleChangeIframeDialog"
    />
    <SnackBar
      v-if="snackbarData.show"
      :text="snackbarData.text"
      :isShowSnackBar="snackbarData.show"
      :timeout="snackbarData.timeout"
      :setSnackBarData="setSnackBarData"
    />
    <div class="form">
      <Form :loadPdf="loadPdf" />
    </div>
    <div class="hidden">
      <div ref="graph" class="pdf-content">
        <PdfHeader />
        <v-row class="row-padding">
          <span class="base-grey-bold text-h5 padding"
            >Entwicklung der Hypothekenzinsen im Zeitraum {{ zeitraum() }}</span
          >
        </v-row>
        <v-row class="row-padding">
          <span class="inder base-bold-color padding">Bedingungen</span>
          <span class="inder"
            >Beleihung:
            <v-chip small class="selected-tag inter">{{
              currentBeleihung
            }}</v-chip></span
          >
          <span class="padding inter"
            >Sollzinsbindung:
            <v-chip small class="selected-tag inter">{{
              currentSollzinsbindung
            }}</v-chip></span
          >
        </v-row>
        <Chart
          style="min-height: 550px; min-width: 1200px"
          :isShow="isShow"
          formPath="formParamHypotheken"
          chartPath="chartParamHypotheken"
          chartDataPath="chartDataHypotheken"
          :minWidth="2.5"
          :maxWidth="1.5"
          minColor="#EBC100"
          maxColor="#181818"
          :period="period"
        />
        <span class="inter"
          >Der Mittelwert basiert auf Konditionen von über
          {{ providerCount }} Anbietern.
        </span>
      </div>
    </div>
    <ModalConfirm
      :textActionButtons="textActionButtons"
      @change="onDialogChanged"
      :dialog="dialog"
      :confirm-action="createReport"
      text="Geben Sie Ihrem Bericht einen Namen"
      title=""
    >
      <div class="report-title">
        <v-form ref="form">
          <v-text-field
            :color="color"
            placeholder="Titel"
            v-model="title"
            :rules="titleRules"
          />
        </v-form>
      </div>
    </ModalConfirm>
    <v-container>
      <div
        v-if="chartDataHypotheken.show && !!chartDataHypotheken.data.length"
        class="mt-10"
      >
        <ChartHeader
          module="param"
          nameSpace="chartParamHypotheken"
          :data="chartDataHypotheken"
          :formParam="copyFormParam"
        />
        <Chart
          :isShow="isShow"
          formPath="formParamHypotheken"
          chartPath="chartParamHypotheken"
          chartDataPath="chartDataHypotheken"
          :minWidth="2.5"
          :maxWidth="1.5"
          minColor="#EBC100"
          maxColor="#181818"
          :period="period"
        />
        <div class="table-container" v-if="!loading">
          <v-row justify="space-between">
            <v-col v-if="permissions.includes('mortgage.competitionData')">
              <v-btn
                :color="color"
                @click="handleChangeShowTable"
                v-if="isAvailable"
                >{{ getTextButton }}</v-btn
              >
              <v-btn
                v-if="isShowTable"
                class="ml-1"
                :color="color"
                @click="exportFile"
                >Download XLS</v-btn
              >
              <v-btn v-if="isShowTable" class="ml-1 csv-btn" :color="color">
                <vue-json-to-csv
                  separator=";"
                  :json-data="rowsCSV"
                  :labels="headersCSV"
                  :csv-title="csvFileName"
                >
                  Download CSV
                </vue-json-to-csv>
              </v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn
                :disabled="loading"
                :loading="loading"
                v-if="!!chartDataHypotheken.data.length"
                class="mr-1 btn"
                :color="color"
                @click="generateIframe"
              >
                Grafik einbinden
                <v-icon small style="color: #fff">mdi-share</v-icon></v-btn
              >
              <v-btn :color="color" @click="openDialog">Speichern</v-btn>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col xs="12" sm="12" md="8" lg="8" xl="8">
              <Alert
                style="text-align: center"
                v-if="isShowHint"
                type="info"
                color="orange"
                message="In der Vollversion können Sie die Datenreihen für den kompletten ausgewählten Zeitraum einsehen und exportieren."
              />
            </v-col>
          </v-row>

          <Table
            v-if="isShowTable"
            :headers="headers"
            :rows="rows"
            ref="table"
          />
        </div>
      </div>
      <div v-else class="wettbewerb-info">
        <p>
          Wählen Sie Vergleichsbanken und definieren Sie einen
          Auswertungszeitraum
        </p>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { uid } from 'uid';
import VueJsonToCsv from 'vue-json-to-csv';

import ChartHeader from '../../../common/Chart/ChartHeader/ChartHeader';
import Chart from '../../../common/Chart/Chart';
import Form from '../Form';
import Loader from '../../../common/Loader/Loader';
import Table from '../../../common/Table/Table';
import ModalConfirm from '../../../common/ModalConfirm/ModalConfirm';
import Alert from '../../../common/Alert/Alert';
import PdfHeader from '../../../../components/common/PdfHeader/PdfHeader';
import CopyIframeDialog from '../../../common/CopyIframeDialog/CopyIframeDialog';
import SnackBar from '../../../../../../src/components/fmh_components/common/SnackBar/SnackBar';

import {
  checkedCheckBoxes,
  createPDF,
  fileName,
  headersForCSV,
  mappedZeitraum,
  rowsForCSV,
  wettbewerbTableHeaders,
} from '../../../../utils/helpers';

import {
  BELEIHUNG_TAGS_OPTIONS,
  PRODUCT_TYPES,
  SOLLZINSBINDUNG_TAGS_OPTIONS,
  URLS,
} from '../../../../utils/constants';
import { BASE_BLACK_COLOR, HOST } from '../../../../../../src/utils/constants';
import { DEFAULT_FORM_PARAM_BAUGELD } from '../../../../store/modules/param';

import { exportXLS } from '../../../../../../src/utils/helpers';
import i18n from '../../../../../../src/plugins/i18n';

export default {
  name: 'NewReportBaugeld',
  components: {
    VueJsonToCsv,
    CopyIframeDialog,
    ChartHeader,
    Chart,
    Form,
    Loader,
    Table,
    ModalConfirm,
    Alert,
    PdfHeader,
    SnackBar,
  },
  computed: {
    ...mapGetters({
      formParam: 'param/getFormParamHypotheken',
      copyFormParam: 'param/getCopyFormParamHypotheken',
      chartParam: 'param/getChartParamHypotheken',
      chartDataHypotheken: 'result/getChartDataHypotheken',
      permissions: 'auth/getPermissions',
      user: 'auth/getUser',
      snackbarData: 'reports/getSnackbarData',
    }),
    csvFileName() {
      const name = fileName();
      const date = `${this.$date().format('DD.MM.YYYY')}`;
      return `${name}_${date}`;
    },
    headersCSV() {
      return headersForCSV(this.headers);
    },
    rowsCSV() {
      return rowsForCSV(this.rows, this.headersCSV, this.$date);
    },
    period() {
      return this.parsePeriod(this.formParam, this.$date);
    },
    isAvailable() {
      return this.permissions.includes('mortgage.competitionData');
    },
    locale() {
      return this.language === 'de' ? 'de-DE' : 'en-US';
    },
    currentBeleihung() {
      const search = BELEIHUNG_TAGS_OPTIONS.find(
        (i) => i.value === this.formParam.beleihung
      );
      return search?.text || '';
    },
    currentSollzinsbindung() {
      const search = SOLLZINSBINDUNG_TAGS_OPTIONS.find(
        (i) => i.value === this.formParam.sollzinsbindung
      );
      return search?.text || '';
    },
    isShowHint() {
      return this.user?.isDemoUser && this.isShowTable;
    },
    isShow() {
      return checkedCheckBoxes(this.chartDataHypotheken);
    },
    getTextButton() {
      return this.isShowTable ? 'Datenreihe ausblenden' : 'Datenreihe anzeigen';
    },
  },
  watch: {
    copyFormParam(val) {
      if (typeof val.bankname1 === 'object' && this.chartDataHypotheken.show) {
        const result = this.filter('zins1');
        this.setChartDataHypotheken(result);
      }
      if (typeof val.bankname2 === 'object' && this.chartDataHypotheken.show) {
        const result = this.filter('zins2');
        this.setChartDataHypotheken(result);
      }
      if (typeof val.bankname3 === 'object' && this.chartDataHypotheken.show) {
        const result = this.filter('zins3');
        this.setChartDataHypotheken(result);
      }
      if (typeof val.bankname4 === 'object' && this.chartDataHypotheken.show) {
        const result = this.filter('zins4');
        this.setChartDataHypotheken(result);
      }
      if (typeof val.bankname5 === 'object' && this.chartDataHypotheken.show) {
        const result = this.filter('zins5');
        this.setChartDataHypotheken(result);
      }
    },
    chartDataHypotheken: {
      immediate: true,
      handler(val) {
        this.headers = wettbewerbTableHeaders(
          this.formParam.bankname1,
          this.formParam.bankname2,
          this.formParam.bankname3,
          this.formParam.bankname4,
          this.formParam.bankname5
        );
        this.rows = [...val?.data].reverse() || [];
        const { zins1, zins2, zins3, zins4, zins5 } = checkedCheckBoxes(
          this.chartDataHypotheken
        );
        if ([zins1, zins2, zins3, zins4, zins5].includes(true)) {
          this.setValueMaxAvgMin({ chartParamHypotheken: false });
        } else {
          this.setValueMaxAvgMin({ chartParamHypotheken: true });
        }
      },
    },
  },
  data() {
    return {
      textActionButtons: {
        confirm: 'Speichern',
        reject: 'Abbrechen',
      },
      color: BASE_BLACK_COLOR,
      loading: false,
      isShowTable: false,
      headers: [],
      rows: [],
      dialog: false,
      title: '',
      titleRules: [],
      language: i18n.locale,
      iframeDialog: false,
      iframe: '',
    };
  },
  methods: {
    ...mapActions({
      saveReport: 'wettbewerb/saveReport',
      saveChartData: 'interestCharts/saveChartData',
    }),
    ...mapMutations({
      setValueMaxAvgMin: 'param/setValueMaxAvgMin',
      setChartDataHypotheken: 'result/setChartDataHypotheken',
      resetStateHypotheken: 'result/resetStateHypotheken',
      setSnackBarData: 'reports/setSnackBarData',
    }),
    handleChangeIframeDialog(val) {
      this.iframeDialog = val;
    },
    async generateIframe() {
      try {
        this.loading = true;
        const data = await this.saveChartData({
          data: this.chartDataHypotheken.data,
        });
        if (data?.data?.slug) {
          const str = JSON.stringify({
            params: {
              page: 'wettbewerb-baugeld',
              chartParam: this.chartParam,
              formParam: this.formParam,
            },
          });
          const objJsonB64 = btoa(str);
          const src =
            process.env.NODE_ENV !== 'production'
              ? `http://localhost:8080/rate-chart/${data.data.slug}/?params=${objJsonB64}`
              : `${HOST}/rate-chart/${data.data.slug}/?params=${objJsonB64}`;
          this.iframe = `<iframe \n  src="${src}" \n width="100%" \n height="900px" \n>
</iframe>`;
          this.iframeDialog = true;
          this.loading = false;
        } else {
          this.setSnackBarData({
            text: 'Bei der API Anfrage trat ein Fehler auf',
            show: true,
            timeout: 3000,
          });
        }
        this.loading = false;
      } catch (e) {
        this.setSnackBarData({
          text: 'Bei der API Anfrage trat ein Fehler auf',
          show: true,
          timeout: 3000,
        });
        this.loading = false;
      }
    },
    zeitraum() {
      return mappedZeitraum(this.formParam, this.locale);
    },
    exportFile() {
      const name = fileName();
      const date = `Es werden die Daten vom ${this.$date().format(
        'DD.MM.YYYY'
      )} angezeigt`;
      exportXLS(this.$refs?.table?.$el, name, date);
    },
    createReport() {
      this.titleRules = [(v) => !!v || 'Titel ist erforderlich'];
      setTimeout(async () => {
        if (this.$refs.form.validate()) {
          this.loading = true;
          const arr = [];
          Object.keys(this.formParam).forEach((key) => {
            if (this.formParam[key]) {
              arr.push(`${key}=${this.formParam[key]}`);
            }
          });
          const str = arr.join('&');
          const paths = this.$router.currentRoute.path.split('/');
          const payload = Object.assign({
            config: {
              url: str,
              host: `${
                URLS[PRODUCT_TYPES[paths[1]]]
              }rechner/webapi/banken-index/index`,
            },
            title: this.title,
            report_type: 6,
            is_temporary: false,
            uid: uid(),
          });
          await this.saveReport(payload);
          this.loading = false;
          this.title = '';
          localStorage.setItem(
            'formParamHypotheken',
            JSON.stringify(DEFAULT_FORM_PARAM_BAUGELD)
          );
          this.resetStateHypotheken();
          return this.$router.push('/wettbewerb-baugeld');
        }
      });
    },
    onDialogChanged(val) {
      this.dialog = val;
    },
    openDialog() {
      this.dialog = true;
    },
    handleChangeShowTable() {
      this.isShowTable = !this.isShowTable;
    },
    filter(key) {
      return this.chartDataHypotheken.data.map((item) => {
        return { ...item, ...{ [key]: null } };
      });
    },
    async loadPdf() {
      if (
        this.chartDataHypotheken.show &&
        !!this.chartDataHypotheken.data.length
      ) {
        this.loading = true;
        await createPDF(
          this.$refs.graph,
          'wettbewerb-baugeld.pdf',
          this.formParam,
          this.$date
        );
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-tag {
  background-color: $fmh-selected !important;
  opacity: 0.8;
  color: white !important;
  padding-bottom: 3px;
}
.hidden {
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.pdf-content {
  width: 100%;
  padding: 30px 60px 30px 60px;
}
.padding {
  padding: 0 15px;
}
.row-padding {
  padding-bottom: 20px;
}
.wettbewerb-table {
  [class~='v-data-table__wrapper'] > thead > tr > [class~='text-start'] {
    display: flex !important;
  }
}
.report-wrapper {
  position: relative;
  overflow-x: hidden;
}
</style>
